export const mineConfig = [
  {
    field: 'clueSource',
    title: '线索来源',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.clueSource || []
    }
  },
  {
    field: 'startTime',
    title: '创建开始时间',
    type: 'date',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM-DD',
    minDate: '2000-01-01',
    maxDate: '2050-10-20'
  },
  {
    field: 'endTime',
    title: '创建结束时间',
    type: 'date',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM-DD',
    minDate: '2000-01-01',
    maxDate: '2050-10-20'
  },
]

export const branchConfig = [
  {
    field: 'userId',
    title: '负责销售',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'selleUserId'
  },
  {
    field: 'clueSource',
    title: '线索来源',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.clueSource || []
    }
  },
  {
    field: 'branchOrgIdList',
    title: '分公司',
    type: 'Array',
    defaultVal: [],
    componentName: 'OldCompBranch'
  },
  {
    field: 'startTime',
    title: '创建开始时间',
    type: 'date',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM-DD',
    minDate: '2000-01-01',
    maxDate: '2050-10-20'
  },
  {
    field: 'endTime',
    title: '创建结束时间',
    type: 'date',
    defaultVal: '',
    componentName: 'SingleDate',
    dateFormat: 'YYYY-MM-DD',
    minDate: '2000-01-01',
    maxDate: '2050-10-20'
  },
]

