<template>
  <div
    class="section"
  >
    <complex
      ref="complex"
      :offset-top="58"
      :data="tableData[tabItem.code]"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      @pullingUp="onPullingUp"
      @pullingDown="onPullingDown"
      @diffChange="diffChange"
    >
      <template slot="header">
        <div class="complex-header">
          <show-search
            v-model="requestData.clueName"
            search-type="clueName"
            placeholder="请输入线索名称"
            :need-sreen="false"
            @getLists="search"
          />
        </div>
      </template>
      <template slot="sticky-ele">
        <show-search
          v-if="scrollDiff >= 1"
          v-model="requestData.clueName"
          search-type="clueName"
          placeholder="请输入线索名称"
          :need-sreen="false"
          @getLists="search"
        />
        <condition-info
          v-model="conditionValue"
          :tabs="tabList"
          :filter-num="filterNum"
          :fast-list="fastList"
          :is-tab-show="scrollDiff < 1"
          @tabChange="tabChange"
          @fastChange="fastChange"
          @toFilter="toFilter"
        />
      </template>
      <template slot="content">
        <div class="content">
          <template
            v-for="(items, i) in tableData"
          >
            <div
              v-if="tabItem.code === i"
              :key="i"
              class="list-wrapper"
            >
              <error-page
                v-if="!items.length"
                class="errorHeight"
              />
              <ul
                v-for="(item, indexs) in items"
                :key="indexs"
                @click="toDetail(item.id, item.mlabel)"
              >
                <card-view :data="item.mainObj">
                  <div v-if="item.mlabel === '我的线索'" />
                </card-view>
              </ul>
            </div>
          </template>
        </div>
      </template>
    </complex>
    <filter-model
      :show.sync="filterModelShow"
      :data.sync="filterData"
      :num.sync="filterNum"
      :nodes="modelConfig"
      :dict="dictList"
      @confirm="filterConfirm"
    />
  </div>
</template>

<script >

import { mapGetters, mapMutations } from 'vuex';

import { clueList, clueOpperLog } from '_api/clue';
import complex from '_c/complex'
import conditionInfo from '_c/condition/filter.vue'
import errorPage from '_c/errorPage';
import FilterModel from '_c/fliter-model'
import showSearch from '_c/search/search-model';
import cardView from '_c/tab-card/card-view';

import { debounce, findValueByKey } from '@/lib/until';
import { branchConfig, mineConfig } from './filterConfig'

export default {
  name: 'ClueManage',
  components: {
    complex,
    conditionInfo,
    FilterModel,
    showSearch,
    cardView,
    errorPage
  },
  data() {
    return {
      aid: [],
      tabList: [],
      requestData: {
        userId: '',
        clueName: '',
        clueSource: '',
        clueStatus: '',
        branchOrgIdList: [],
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 10
      },
      tableData: [[], []],
      index: 0,
      needIcon: true,
      scrollDiff: 0,
      conditionValue: {
        tabItem: { code: 0 },
        fastItem: { code: '' }
      },
      filterModelShow: false,
      filterData: {},
      filterNum: 0,
      // 在路由撤退时用来触发子组件数据更新
      activatedNum: 0
    };
  },
  computed: {
    ...mapGetters('clue', ['filterObj', 'aidList']),
    ...mapGetters(['dictList']),
    loadEnable() {
      return this.tableData[this.index] ? !!this.tableData[this.index].length : true;
    },
    tabItem() {
      return this.conditionValue.tabItem || {}
    },
    fastItem() {
      return this.conditionValue.fastItem || {}
    },
    fastList() {
      let list = []
      if (Array.isArray(this.dictList.clueStatus)) {
        list = this.dictList.clueStatus.map(item => ({ ...item, label: item.text, code: item.value }))
      }
      return list
    },
    modelConfig() {
      let config = []
      if (this.isMineTab(this.tabItem.code)) {
        config = mineConfig
      } else if (this.isBranchTab(this.tabItem.code)) {
        config = branchConfig
      }
      return config
    }
  },
  provide() {
    return {
      manageContainer: this
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.activatedNum++
  },
  methods: {
    ...mapMutations(['CLEAR_FILTER', 'SET_KEEPALIVE']),
    getLists(requestData, aid, index, mlabel, type) {
      if (!this.tableData[index].length) this.$loading.show();
      clueList(requestData, aid[index]).then((res) => {
        this.$loading.hide();
        if (res.flag) {
          const tableData = [[], []];
          const list = res.data.pageInfo.list || [];
          if (list.length > 0) {
            if (type === 'upload') {
              tableData[index] = this.tableData[index].concat(list);
            } else {
              tableData[index] = list;
            }
            tableData[index].forEach(item => {
              item.mlabel = mlabel;
              item.mainObj = {
                title: item.clueName,
                status: findValueByKey(this.dictList.clueStatus, item.clueStatus),
                mainList: [
                  [item.contactsName, item.contactsPost, item.contactsMobile].filter(Boolean).join(' | ')
                ],
                tagList: [
                  findValueByKey(this.dictList.clueSource, item.clueSource)
                ].filter(Boolean),
                userTitle: item.userTitle,
                branchOrgTitle: item.branchOrgTitle,
                orgTitle: item.orgTitle
              };
            });
            this.$set(this.tableData, index, tableData[index]);
            this.tabList[index].tableData = tableData[index];
            if (this.$refs.complex) this.$refs.complex.upload = true;
          } else {
            if (requestData.pageNum === 1) {
              this.$set(this.tableData, index, []);
            } else {
              if (this.$refs.complex) this.$refs.complex.upload = false;
              this.$refs.complex.onPullingUp();
            }
          }
          if (mlabel === '本部门及以下') this.tabList[index].num = res.data.pageInfo.total ? res.data.pageInfo.total : 0;
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toDetail(id, mlabel) {
      clueOpperLog(id).then(res => {
        if (res.flag) {
          this.SET_KEEPALIVE([`${this.$route.name}`]);
          const index = mlabel === '我的线索' ? '0' : '1';
          this.$router.push(
            {
              name: 'ClueDetails',
              params: {
                clueId: id,
                type: index
              }
            }
          );
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toFilter() {
      this.filterModelShow = true
      // this.SET_KEEPALIVE([`${this.$route.name}`]);
      // const index = this.tabList[this.index].label === '我的线索' ? '0' : '1';
      // this.$router.push(
      //   {
      //     name: 'ClueFilter',
      //     params: {
      //       i: index
      //     }
      //   }
      // );
    },
    getIndex(index) {
      this.index = index;
      this.$set(this.tableData, index, []);
      // this.CLEAR_FILTER('clue');
      this.filterData = {}
      const cleard = debounce(this.clear, 500);
      cleard();
    },
    clear() {
      for (const i in this.requestData) {
        if (i === 'branchOrgIdList') {
          this.requestData[i] = [];
        } else {
          this.requestData[i] = '';
        }
      }
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.tableData[this.index] = [];
      this.filterNum = 0
      this.refreshLoad();
    },
    init() {
      // this.getGetters();
      this.tabList = [];
      this.aidList.forEach((item, i) => {
        this.tabList.push({ label: item.mlabel, value: i, code: i, aidList: item.actions, num: '' });
        item.actions.forEach(itemA => {
          switch (itemA.acode) {
            case 'query':
              this.aid.push(itemA.aid);
              break;
          }
        });
        this.getLists(this.requestData, this.aid, i, item.mlabel);
      });
    },
    fromFilter() {
      const i = this.index;
      // this.getGetters();
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
    },
    // 获取vuex数据
    // getGetters() {
    //   // for (const i in this.filterObj) {
    //   //   if (i === 'branchOrgIdList') {
    //   //     this.requestData[i] = this.filterObj[i].value || [];
    //   //   } else {
    //   //     this.requestData[i] = this.filterObj[i].value || '';
    //   //   }
    //   //   // this.requestData[i] = this.filterObj[i].value || '';
    //   // }
    // },
    refreshLoad(val, type) {
      const i = this.index;
      const clueName = this.requestData.clueName || val;
      this.getLists({ ...this.requestData, clueName }, this.aid, i, this.aidList[i].mlabel, type);
    },
    search(val, type) {
      this.requestData.pageNum = 1
      this.refreshLoad(val, type)
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    tabChange() {
      this.conditionValue.fastItem = { code: '' }
      this.requestData.clueStatus = ''
      this.getIndex(this.tabItem.code)
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    fastChange() {
      this.requestData.clueStatus = this.fastItem.code
      this.requestData.pageNum = 1
      this.fromFilter()
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    // 下拉刷新
    onPullingDown() {
      this.requestData.pageNum = 1
      this.refreshLoad('', 'refresh')
    },
    // 上拉加载
    onPullingUp() {
      this.requestData.pageNum++
      this.refreshLoad('', 'upload')
    },
    diffChange(diff) {
      if (diff > 1) {
        this.scrollDiff = 1
      } else {
        this.scrollDiff = diff
      }
    },
    isMineTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('我的'))
    },
    isBranchTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('部门'))
    },
    filterConfirm() {
      this.setRequestData()
      const i = this.index;
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
      this.filterModelShow = false
    },
    setRequestData() {
      for (const key in this.filterData) {
        this.requestData[key] = this.filterData[key]
      }
    }
  }
};
</script>

<style  lang="scss"  scoped>
.complex-header{
  width: 100%;
  height: 58px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: none;
}
.errorHeight{
  height:calc(100vh - 118px);
}
</style>
